
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'about-us-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('aboutUsListing'), [
          translate('settings'),
        ]);
        loading.value = true;

        await store.dispatch(Actions.GET_ABOUT_US);
        loading.value = false;
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_ABOUT_US_STORE);
      });

      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('type'),
          key: 'type',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const editGroup = (id) => {
        router.push({ name: 'about-us-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ABOUT_US_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_ABOUT_US);
        loading.value = false;
      };

      return {
        tableData: computed(() => store.getters.aboutUsList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.aboutUsListCount),
        pageChanged,
        goTo,
        editGroup,
        loading,
        can,
      };
    },
  });
